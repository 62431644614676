<!-- <div> -->
<p style="font-size: medium; color: black">Preview</p>
<div class="d-flex justify-content-between" style="padding: 8px">
  <div>
    <label>Task Name:</label><span> {{ taskname }}</span>
  </div>
  <div>
    <span style="color: gray">Task ID:</span><span> {{ taskid }}</span>
  </div>
</div>
<div style="min-width: 200px; position: relative">
  <div id="cesiumContainer" class="cesiumContainer" style="height: 400px"></div>
  <div
    style="
      position: relative;
      background: black;
      color: white;
      z-index: 1;
      top: -35px;
      padding: 3px 8px;
    "
  >
    <label style="padding-bottom: 5px">AREA OF INTEREST</label>
    <div style="font-size: small">
      <p>
        <label style="color: lightgray">Geometry:</label>
        <span *ngIf="isCircle; else other_content"> Circle</span>
        <ng-template #other_content> {{ taskTarget.type }}</ng-template>
      </p>

      <p>
        <label style="color: lightgray"> Coordinates (Lon, Lat): </label>
        <span> </span>
        <span>
          [{{ coordinates | json | slice: 1:35 }}...]<img
            style="margin: 3px; background: white; border-radius: 50%"
            width="20px"
            src="assets/images/info.svg"
            [matTooltip]="coordinates | json"
            matTooltipPosition="left"
          />
        </span>
      </p>
    </div>
  </div>
  <div class="d-flex justify-content-center" style="margin-top: -15px">
    <button
      mat-button
      class="btn"
      (keydown)="onKeyPress($event, 'NO')"
      (click)="onNoClick('NO')"
    >
      Close
    </button>
  </div>
</div>

<!-- </div> -->
