import { Component, OnInit, Inject, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ValidationService } from '../../services/validation.service';
import { AuthService } from '../../services/auth.service';
import { UserModel } from '../../models/api.model';
import { UserService } from '../../services/user.service';
import { LoginWrapperComponent } from '../userlogin/login-wrapper.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-userregister',
  templateUrl: './userregister.component.html',
  styleUrls: ['./userregister.component.scss'],
})
export class UserregisterComponent implements OnInit {
  panelOpenState = false;
  userEmailForm: FormGroup | any;
  userForm: FormGroup | any;
  returnUrl = '';
  currentUser = '';
  users: UserModel[] = [];
  emailRegisterd = false;
  hide = true;
  debouncer: any;
  imgUrl = '';
  userPhotoFile: any;
  validationMessages: any;
  pswrdMatched = true;
  curTheme: any;
  warnMsg: any;
  validPhone = false;
  notRegistered = false;
  success = false;
  newOTP = '';
  otpVerified = false;
  proceed = false;
  oversize = false;
  imageType = false;
  processing = false;
  //   constructor(private commonSer: CommonService,  private fb: FormBuilder, private vs: ValidationService,
  //               public thisDialogRef: MatDialogRef<UserregisterComponent>,
  //               @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
  //               private router: Router, private route: ActivatedRoute, private authService: AuthService,
  //               private us: UserService, private sanitize: DomSanitizer, private injector: Injector) {}
  constructor(
    private commonSer: CommonService,
    private fb: FormBuilder,
    private vs: ValidationService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private us: UserService,
    private sanitize: DomSanitizer,
    private injector: Injector
  ) {}
  //only number will be add
  ngOnInit() {
    // const newUser: UserModel = this.loginForm.value;
    // this.authService.logout();
    // if (this.data) { this.returnUrl = this.data.returnUrl; }

    this.userEmailForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          // tslint:disable-next-line: max-line-length
          Validators.pattern(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
          ),
        ]),
      ],
    });
    this.userForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
          this.vs.userNameValidator,
        ],
      ],
      displayname: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(15),
          this.vs.userNameValidator,
        ],
      ],
      company: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(80),
          this.vs.userNameValidator,
        ],
      ],
      // tel: new FormControl(new MyTel('', '', '')),
      phone: [
        '',
        [
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      phoneOtp: [''],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(25),
        ]),
      ],
      // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9#%@&*._$!()]+$')])],
      confirmpassword: ['', Validators.required],
      // confirmpassword: ['', Validators.required, this.vs.confirmpswrdValidator],
      photo: [''],
    });
    this.validationMessages = this.vs.account_validation_messages;
  }
  keyPressName(event: any) {
    let value = event.target.value;
    if (value.length === 1) {
      value = value.toUpperCase();
    }
    const pattern = /[ a-z + \ A-Z\ + \ \ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (
      (event.keyCode != 8 && !pattern.test(inputChar)) ||
      value.length > 50 ||
      (inputChar === '0' && value.length === 0)
    ) {
      event.preventDefault();
    }
  }
  keyPressCompany(event: any) {
    let value = event.target.value;
    if (value.length === 1) {
      value = value.toUpperCase();
    }
    const pattern = /[ a-z + \ A-Z\ + \ \ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (
      (event.keyCode != 8 && !pattern.test(inputChar)) ||
      value.length > 80 ||
      (inputChar === '0' && value.length === 0)
    ) {
      event.preventDefault();
    }
  }
  chngPhone() {
    this.warnMsg = '';
  }
  keyPress(event: any) {
    const value = event.target.value;
    if (this.validPhone) {
      this.validPhone = false;
    }
    if (value.length === 9) {
      this.validPhone = true;
      return;
    }
    // const pattern = /[0-9\+\-\ ]/;
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (
      (event.keyCode != 8 && !pattern.test(inputChar)) ||
      value.length > 9 ||
      (inputChar === '0' && value.length === 0)
    ) {
      event.preventDefault();
    }
  }
  areEqual(event: any) {
    this.pswrdMatched = false;
    if (this.userForm.value.password === event.target.value) {
      this.pswrdMatched = true;
      // this.emailRegisterd = true;
    } else {
      this.pswrdMatched = false;
      // this.emailRegisterd = false;
    }
    // return {areEqual: true};
    // console.log(this.pswrdMatched)
  }

  validEmail(): any {
    return { validEmail: true };
  }

  async chkUser() {
    this.processing = true;
    this.warnMsg = '';
    const userEmail = this.userEmailForm.value.email.toLowerCase();
    const pswrd = this.userForm.value.password || 'null';
    const loginparams = encodeURIComponent(
      JSON.stringify({ email: userEmail, password: pswrd })
    );
    this.authService.loginwithEmail(loginparams);
    this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;
      if (res) {
        this.onCloseCancel();
      } else {
        let result: any;
        result = await JSON.parse(
          sessionStorage.getItem(this.commonSer.JWT_TOKEN)
        );
        if (result) {
          if (result.Msg === 'User not registered') {
            // this.warnMsg = 'User with this email not regisred';
            this.notRegistered = true;
          }
          if (result.Msg === 'Wrong password') {
            this.notRegistered = false;
            this.warnMsg = 'User already registered with this email';
          }
        }
        // this.warnMsg = result.Msg;
        // console.log(result.Msg);
      }
    });
  }
  clearImages() {
    this.imgUrl = '';
  }
  onFileSelected(event: any) {
    // this.userPhotoFile = this.sanitize.bypassSecurityTrustResourceUrl( event.target.files[0]);
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    this.userPhotoFile = event.target.files[0];
    if (!allowedExtensions.exec(this.userPhotoFile.name)) {
      this.imageType = true;
      this.imgUrl = '';
    } else {
      this.imageType = false;
      if (this.userPhotoFile.size < 20480) {
        /* less than 20kb */
        this.oversize = false;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imgUrl = e.target.result;
        };
        reader.readAsDataURL(this.userPhotoFile);
      } else {
        this.oversize = true;
        this.imgUrl = '';
      }
    }
  }

  submitForm(event: any) {
    if (event.keyCode === 13) {
      this.onCloseConfirm();
    }
  }
  // genOTP(phone: string) {
  //   phone = '9136390654';
  //   if(!this.newOTP) {
  //     this.us.genOTP(phone).subscribe( (res: any) =>{
  //       if (res) {
  //         this.newOTP = res.toString();
  //         this.warnMsg = 'Check your mobile for OTP.';
  //       }
  //       // console.log(res)
  //     });
  //   }
  // }
  //   verifyOTP() {
  //     this.warnMsg = 'Wrong OTP entered.'
  //     let otp = this.userForm.value.phoneOtp;
  //     this.otpVerified = false;
  //     // console.log(this.newOTP, otp)
  //     if (otp === this.newOTP) {
  //       this.warnMsg = '';
  //       this.otpVerified = true;
  //       // console.log(this.otpVerified)
  //     } else {
  //       this.warnMsg = 'Wrong OTP entered.';
  //     }
  //     this.userForm.value.phoneOtp = '';
  //  }
  onCloseConfirm() {
    let userName = this.userForm.value.name;
    userName = userName.replace(/\w\S*/g, function (txt: any) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    const displayName = this.userForm.value.displayname;
    const company = this.userForm.value.company;
    const email = this.userEmailForm.value.email.toLowerCase();
    const phone = this.userForm.value.phone;
    const pswrd = this.userForm.value.password.trim();
    const re_pswrd = this.userForm.value.confirmpassword.trim();
    if (pswrd != re_pswrd) {
      this.pswrdMatched = false;
      return;
    }
    const formData = new FormData();
    formData.append('name', userName);
    formData.append('displayname', displayName);
    formData.append('company', company);
    formData.append('phone', phone);
    if (email.length > 2) {
      formData.append('email', email);
    }
    formData.append('password', pswrd);
    formData.append('photoFile', this.userPhotoFile);
    // this.us.genOTP(phone).subscribe( (res: any) =>{
    //   console.log(res)
    this.us.addUser(formData).subscribe((res: any) => {
      // console.log(res)
      if (res.Msg.includes('Successfully registered')) {
        // if (email) {
        //   const mailObj = {
        //     id: email ,
        //     sub: 'Thank you',
        //     content: 'Thank you for registering with www.risingsunmart.com'
        //   }
        //   this.us.sendMail(mailObj).subscribe( (res: any) =>{
        //     console.log(res);
        //   });
        // }
        this.success = true;
        this.warnMsg = res.Msg;
        sessionStorage.removeItem(this.commonSer.JWT_TOKEN);
      }
      // console.log(res);
    });
    // });
    // console.log(this.us.addUser(formData));
    // this.onCloseCancel();
  }
  onCloseCancel() {
    // this.thisDialogRef.close('Cancel');
  }
  openDialogLogin() {
    this.router.navigate(['login']);
    // this.thisDialogRef.close('Cancel');
    // // const prodSer = this.injector.get(ProductService);
    // // prodSer.openDialogLogin();
    // this.dialog.open(LoginWrapperComponent);
  }
}
