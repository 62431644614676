import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { ThemePalette } from '@angular/material/core';
import * as Cesium from 'cesium';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
// import { TasksService } from './tasks.service';
import { BehaviorSubject, Subscription } from 'rxjs';
// import { toUnicode } from 'punycode';
var parse = require('wellknown');
import pdfmake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { style } from '@angular/animations';
pdfmake.vfs = pdfFonts.pdfMake.vfs;

export interface Sensor {
  name: string;
  type: string;
  selected: boolean;
  color: ThemePalette;
  sensor?: Sensor[];
  available: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  currentUser: any;
  title = ''; //"Intelli MultiSatTasking";
  contactMail = 'no-reply@eartheye.space';
  server = environment.eeTaskServer; //+ '/satellogic';
  mailServer = environment.mailServer;
  // eePGUrl = environment.eePGUrl;

  taskitems: any = [];
  public taskparams = new BehaviorSubject<any>(this.taskitems);
  Tasks = this.taskparams.asObservable();

  items: any = [];
  private itemSource = new BehaviorSubject<any>(this.items);
  selectedSensors = this.itemSource.asObservable();

  private cart: any = [];
  cartBS = new BehaviorSubject<any>(this.cart);
  castCartData = this.cartBS.asObservable();
  config: any;
  private order: any = [];
  orderBS = new BehaviorSubject<any>(this.order);
  castOrderData = this.orderBS.asObservable();

  readonly usersImgUrl: string = environment.usersServer + '/data/';
  readonly collectionUrl: string = environment.eeTaskServer;
  constructor(
    private http: HttpClient,
    private location: Location,
    private authService: AuthService
  ) {
    this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;
    });
  }

  async apiUrls(): Promise<string> {
    let conf = await this.getConfig();
    console.log(conf);
    return Promise.resolve(conf);
  }

  private async getConfig(): Promise<any> {
    if (!this.config) {
      this.config = await this.http
        .get(this.location.prepareExternalUrl('/assets/config.json'))
        .toPromise();
    }
    return Promise.resolve(this.config);
  }

  dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  euroGerman = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  BASELAYER = new Cesium.TileMapServiceImageryProvider({
    url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
  });

  BASELAYERS = [
    {
      name: 'Sentinel-2',
      url: new Cesium.ProviderViewModel({
        name: 'Sentinel-2',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/sentinel-2.png'
        ),
        tooltip: 'Sentinel-2 cloudless',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3954 });
        },
      }),
    },
    {
      name: 'Natural Earth',
      url: new Cesium.ProviderViewModel({
        name: 'Natural Earth',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/naturalEarthII.png'
        ),
        tooltip: 'Blue Marble Next Generation July, 2004 imagery from NASA.',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3813 });
        },
      }),
    },
    {
      name: 'Bing Maps Road',
      url: new Cesium.ProviderViewModel({
        name: 'Bing Maps Road',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingRoads.png'
        ),
        tooltip: 'Bing Maps Road',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 4 });
        },
      }),
    },
    {
      name: 'OSM',
      url: new Cesium.ProviderViewModel({
        name: 'OpenStreetMap',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/openStreetMap.png'
        ),
        tooltip: 'Open Street Map.',
        creationFunction: function () {
          return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          });
        },
      }),
    },
  ];
  // BASELAYER.name = 'Natural Earth II';

  // GRAYGLOBE = new Cesium.TileMapServiceImageryProvider({
  //   url: `http://localhost:8080/GlobeGrey`,
  //   maximumLevel: 7,
  //   credit: "<a style='padding-left: 100px' class='credit' target='_blank' href='https://rk.freegyanhub.com'>R K Singh</a>",
  // });
  // grayglobe.name = 'Gray Globe';

  NAVLIST = ([] = [
    { name: 'Home', url: 'home', active: true, focus: true },
    { name: 'Api Sample', url: 'apisample', active: false, focus: false },
    { name: 'My Tasks', url: 'tasks', active: false, focus: false },
    { name: 'My Collections', url: 'collections', active: false, focus: false },
  ]);
  NAVLISTRT = ([] = [
    // {name: 'Create new task', url:'tasks/newtask', active:false, focus: false},
    { name: 'Tasks', url: 'tasks/mytasks', active: true, focus: true },
    {
      name: 'Collections',
      url: 'tasks/collections',
      active: false,
      focus: false,
    },
    { name: 'Orders', url: 'tasks/orders', active: false, focus: false },
    // {name: 'About', url:'aboutus', active:false},
    // {name: 'Support', url:'support', active:false}
  ]);
  MYSUBMENU = ([] = [
    { name: 'Login', url: 'login', action: 'login()', active: false },
    { name: 'Cart', url: '/cart', active: false },
    { name: 'Orders', url: '/orders', active: false },
    { name: 'Sensors', url: '/admin/sensors', active: false },
    { name: 'Profile', url: '/profile', active: false },
    { name: 'Register', url: 'register', active: false },
    { name: 'Logout', url: 'logout', active: false },
  ]);
  SATELLOGICTASKS = ([] = [
    { name: 'Authorise', value: 'auth' },
    { name: 'Scence', value: 'scenes' },
    { name: 'Get product list', value: 'productlist' },
    { name: 'Get task list', value: 'tasklist' },
    { name: 'Submit new task(Pt)', value: 'newtaskPt' },
    { name: 'Submit new task(Poly)', value: 'newtaskPoly' },
    { name: 'Query task status', value: 'taskstatus' },
    { name: 'Get capture list', value: 'capturelist' },
    { name: 'Cancel a Task', value: 'canceltask' },
  ]);
  AT21TASKS = ([] = [
    { name: 'Catalog retrieval', value: 'catalog retrieval' },
    { name: 'Catalog detailed Information', value: 'Catalog detailed' },
    { name: 'Archive product ordering', value: 'Archive product ordering' },
    { name: 'Programming order', value: 'Programming order' },
    { name: 'Order retrieval', value: 'Order retrieval' },
    { name: 'Order acquisition details', value: 'Order acquisition details' },
    { name: 'Order management', value: 'Order management' },
    { name: 'Order data acquisition', value: 'Order data acquisition' },
    { name: 'Order acquisition feedback', value: 'Order acquisition feedback' },
  ]);
  SENSORSTYPE = [
    // {name: 'All', value: 'MSI/SAR/HSI/FMV', color: 'primary', 'selected': true, 'available': true},
    {
      name: 'MSI',
      value: 'MSI',
      color: 'primary',
      selected: false,
      available: true,
    },
    {
      name: 'SAR',
      value: 'SAR',
      color: 'primary',
      selected: false,
      available: true,
    },
    {
      name: 'HSI',
      value: 'HSI',
      color: 'primary',
      selected: false,
      available: true,
    },
    {
      name: 'FMV',
      value: 'FMV',
      color: 'primary',
      selected: false,
      available: true,
    },
  ];
  TASKFREQUENCY = [
    {
      name: 'Daily',
      value: 'daily',
      color: 'primary',
      selected: true,
      available: true,
    },
    {
      name: 'Weekly',
      value: 'weekly',
      color: 'primary',
      selected: false,
      available: true,
    },
    {
      name: 'Monthly',
      value: 'monthly',
      color: 'primary',
      selected: false,
      available: true,
    },
  ];
  PAYOPTIONS = [
    { name: 'Pay now', value: '0', selected: true },
    { name: 'Debit account', value: '1', selected: false },
    { name: 'Pay later', value: '2', selected: false },
  ];
  sleep(waitingtime: any) {
    setTimeout(function () {}, waitingtime);
  }
  selectSensors(items: any) {
    // console.log(items);
    this.itemSource.next(items);
  }
  updateTaskParams(items: any) {
    // console.log(items);
    this.taskparams.next(items);
  }
  // getSubscription(subscription: string) {
  //   //   console.log(params);
  //   const res = this
  //       .http
  //       .get(`${this.eePaypalUrl}paynow/${subscription}`);
  //   return res;
  // }

  // genOrder(orderBody: any) {
  //   // console.log(option, orderBody); return
  //   let url = '';
  //   const key = 'EarthEye';
  //   if (option === 0) {
  //     url = `${this.server}/order/paynow/${key}`;
  //   } else if (option === 1) {
  //     url = `${this.server}/order/payfromac/${key}`;
  //   } else if (option === 2) {
  //     url = `${this.server}/order/paylater/${key}`;
  //   }
  //   const res = this
  //     .http
  //     .post(`${url}`, {orderBody});
  //   return res;
  //   // res.subscribe(result => {
  //   //     console.log(result)
  //   // })
  // }

  orderdata(userid: string, params: any) {
    params = JSON.stringify(params);
    // console.log(userid, params);
    const res = this.http.get(
      `${this.server}/order/getorder/${userid}/${params}`
    );
    // res.subscribe((res: any) => {
    //   // console.log(res.result)
    //   this.orderBS.next(res.result);
    // });
    return res;
  }
  addOrder(order: any, option: number) {
    const orderBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email },
      order,
    };
    // console.log(option, orderBody); return
    let url = '';
    const key = 'EarthEye';
    if (option === 0) {
      url = `${this.server}/order/paynow/${key}`;
    } else {
      // } else if (this.data.taskaction === 'taskaccept') {
      //   this.title = 'Task Status';
      const taskBody = {
        user: { name: this.currentUser.name, email: this.currentUser.email },
        order,
      };
      let route = 'tasks';
      url = `${this.server}/order/addorder/${key}`;
      this.http
        .post(`${this.server}/${route}/taskaccept/${order.taskid}`, {
          taskBody,
        })
        .subscribe((res: any) => {
          return res;
        });
    }
    const res = this.http.post(`${url}`, { orderBody });
    return res;
  }

  debitAccount(order: any, option: number) {
    const orderBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email },
      order,
    };
    let url = `${this.server}/order/debitnow`;
    const res = this.http.post(`${url}`, { orderBody });
    return res;
  }

  /**
   * updating payment status in task collection when pay now payment successfull
   * @param transactionId
   * @returns
   */
  updatePaymentStatus(transactionId: any) {
    const res = this.http.post(`${this.server}/order/success`, {
      taskId: localStorage.getItem('taskId'),
      orderId: localStorage.getItem('orderID'),
      user: { name: this.currentUser.name, email: this.currentUser.email },
    });
    return res;
  }

  /**
   * Removing payment record from order collection when pay now payment failed
   * @param transactionId
   * @returns
   */
  removeOrder() {
    const res = this.http.post(`${this.server}/order/canceled`, {
      taskId: localStorage.getItem('taskId'),
    });
    return res;
  }

  /**
   * creating payment intent for paypal when user add money to wallet
   * @param amount
   * @returns
   */
  addMoneyToWallet(amount: any) {
    const key = 'EarthEye';
    let url = `${this.server}/order/addmoney/${key}`;
    const orderBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email },
      amount: amount,
    };
    const res = this.http.post(`${url}`, { orderBody });
    return res;
  }

  delOrderdata(orderid: string) {
    const res = this.http.get(`${this.server}/order/delete/${orderid}/`);
    return res;
  }

  cartdata(userid: string) {
    const res = this.http
      .get(`${this.server}/cart/getcart/${userid}/`)
      .subscribe((res: any) => {
        const cart = [];
        res.result.forEach((row: any) => {
          const oprtrs = JSON.parse(row.cartdata);
          let taskcost = 0;
          oprtrs.forEach((oprtr) => {
            taskcost += oprtr.cost;
          });
          row.taskcost = taskcost;
          row.cartdata = oprtrs;
          cart.push(row);
        });
        // console.log(cart)
        this.cartBS.next(cart);
      });
    // return res;
  }
  // add2Cart(userid: string) {
  //   let cartBody: any;
  //   const subscription: Subscription = this.Tasks.subscribe(params => {
  //     let selectedSensors = [], taskcost = 0;
  //     params.sensors.forEach(item => {
  //       if (item.checked) {
  //         let curLatency: any;
  //         item.latency.forEach(latency => {
  //           if (latency.selected) curLatency = latency;
  //         });
  //         selectedSensors.push({
  //           name: item.name, sensor: item.sensor, resolution: item.resolution,
  //           nadir: item.nadir, area: item.area_calculated, cloudcover: item.cloudcover, priority: curLatency.name, latency: curLatency.latency, cost: item.taskcost
  //         })
  //         taskcost += item.taskcost;
  //       }
  //     });

  //     let cartdata = JSON.stringify(selectedSensors);

  //     cartBody = {
  //       user: userid,
  //       project: params.project,
  //       taskname: params.taskname,
  //       target: params.wkt,
  //       cartdata: cartdata,
  //       save4later: params.save4later,
  //       openWindow: params.openWindow,
  //       closeWindow: params.closeWindow,
  //       taskcost: taskcost,
  //     };
  //   });
  //   subscription.unsubscribe();
  //   // console.log(cartBody)
  //   this.cartBS.next(cartBody);
  //   // return cartBody;
  // }
  updateCartDB(userid: string, cartbody: any) {
    const customerid = cartbody.customerid,
      project = cartbody.project,
      taskname = cartbody.taskname,
      target = cartbody.target,
      cartdata = JSON.stringify(cartbody.cartdata),
      taskid = cartbody.taskid,
      openWindow = cartbody.openWindow,
      closeWindow = cartbody.closeWindow,
      taskcost = cartbody.taskcost,
      taskFrequency = cartbody.taskFrequency,
      checked = cartbody.checked;

    // const cartBody = {customerid, taskid, project, taskname, createdAt, target, cartdata, taskcost, checked};
    const cartBody = {
      customerid,
      taskid,
      project,
      taskname,
      openWindow,
      closeWindow,
      target,
      cartdata,
      taskcost,
      checked,
      taskFrequency,
    };
    // console.log(cartbody)
    let result = '';
    const res = this.http
      .post(`${this.server}/cart/add2cart/${userid}`, { cartBody })
      .subscribe((res: any) => {
        // console.log(res.cart, res.Msg)
        // if (res.cart.length>0) this.cartBS.next(res.cart);
        return 'Success';
      });
  }
  delCartdata(cartid: string) {
    let cartdetail = { taskid: cartid, item: -9 };
    if (cartid.includes('#')) {
      const taskid = cartid.split('#')[0];
      const item = parseInt(cartid.split('#')[1]);
      cartdetail = { taskid, item };
    }
    // console.log(cartdetail); return
    const res = this.http.post(`${this.server}/cart/delete`, { cartdetail });
    return res;
  }
  taskIdResponse(action: string, tasks: any) {
    // console.log(action, tasks); //return
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email },
      tasks,
    };
    let route = 'tasks';
    const res = this.http.post(
      `${this.server}/${route}/${action}/${tasks.taskid}`,
      { taskBody }
    );
    return res;
  }

  taskResponse(params: string, userid: string, taskBody: any) {
    // console.log(taskBody)
    if (params !== 'newtask') {
      // for get or patch request
      const taskid = taskBody;
      const res = this.http.get(
        `${this.server}/index/${params}/${userid}/${taskid}`
      );
      return res;
    }
  }
  testmail() {
    const result = this.http.get(`${this.server}/tasks`).subscribe((res) => {
      console.log(res);
    });
  }
  newTaskSubmit(tasks: any) {
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email },
      tasks,
    };
    let result;
    result = this.http.post(`${this.server}/tasks/newtask`, { taskBody });
    return result;
  }
  genCollection(userid: any, useremail, tasks: any) {
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email },
      tasks,
    };
    // console.log(params, this.server); return
    let result;
    result = this.http.post(
      `${this.server}/tasks/collection/${userid}/${useremail}`,
      { taskBody }
    );
    return result;
  }

  async genReport(orders: any) {
    //  console.log(this.currentUser)
    let reportType = 'Order Report',
      logo;
    const exs = [];
    const receiptNo = null;
    const paidamout = 0;
    const orderamount = 0;
    const grandTotal = orderamount + (orderamount * environment.gst) / 100;
    const balance = grandTotal - paidamout;
    const logoimge = '../assets/images/logo.png';
    const customer = this.currentUser;
    let address, fullAddress;
    if (this.currentUser.address) {
      address = JSON.parse(this.currentUser.address);
      fullAddress =
        address.housedetail + ' - ' + address.pincode + ', ' + address.country;
    } else {
      fullAddress = 'Not available';
    }
    await this.toDataURL(logoimge).then((dataUrl) => {
      logo = dataUrl;
    });
    // console.log(logo)
    exs.push([
      {
        columns: [
          {
            width: 50,
            alignment: 'center',
            fontSize: 10,
            text: 'Item No.',
            style: 'tableHeader',
          },
          {
            width: 100,
            alignment: 'center',
            fontSize: 10,
            text: 'Order Id',
            style: 'tableHeader',
          },
          {
            width: 100,
            alignment: 'center',
            fontSize: 10,
            text: 'Order Date',
            style: 'tableHeader',
          },
          {
            width: 120,
            alignment: 'center',
            fontSize: 10,
            text: 'Description',
            style: 'tableHeader',
          },
          {
            width: 60,
            alignment: 'center',
            fontSize: 10,
            text: 'Order Value',
            style: 'tableHeader',
          },
          {
            width: 80,
            alignment: 'center',
            fontSize: 10,
            text: 'Order Status',
            style: 'tableHeader',
          },
        ],
      },
    ]);
    orders.forEach((order, index) => {
      const description = order.taskid;
      const status =
        order.orderstatus.charAt(0).toUpperCase() + order.orderstatus.slice(1);

      // const description = order.project.concat(' - ', order.taskiname);
      const orderamount = this.dollarUS.format(order.orderamount);
      // exs.push(
      //   [{text: 'Header 1', style: 'tableHeader'}, {text: 'Header 2', style: 'tableHeader'}, {text: 'Header 3', style: 'tableHeader'}],
      //   ['Sample value 1', 'Sample value 2', 'Sample value 3'],
      //   ['Sample value 1', 'Sample value 2', 'Sample value 3'],
      //   ['Sample value 1', 'Sample value 2', 'Sample value 3'],
      //   ['Sample value 1', 'Sample value 2', 'Sample value 3'],
      //   ['Sample value 1', 'Sample value 2', 'Sample value 3'],
      // );
      exs.push([
        {
          columns: [
            { width: 50, alignment: 'center', fontSize: 10, text: index + 1 },
            { width: 100, alignment: 'center', fontSize: 10, text: order.id },
            {
              width: 100,
              alignment: 'center',
              fontSize: 10,
              text: new Date(order.createdAt).toLocaleDateString(),
            },
            {
              width: 120,
              alignment: 'center',
              fontSize: 10,
              text: description,
            },
            { width: 60, alignment: 'center', fontSize: 10, text: orderamount },
            { width: 80, alignment: 'center', fontSize: 10, text: status },
          ],
        },
      ]);
    });

    let docDefinition = {
      footer: {
        alignment: 'center',
        columns: [
          {
            text: 'Eartheye Space Technologies Pte Ltd, 1 North Bridge Road, #18-03, High Street Centre, Singapore 179094',
            style: 'CompanyAddress',
          },
        ],
      },
      content: [
        // {
        //   image: logo,
        //   width: 100,
        //   style: 'logostyle',
        //   margin: [0, -10, 0, 2]
        // },
        // {
        //   text: 'Eartheye Space Technologies Pte Ltd, 1 North Bridge Road, #18-03, High Street Centre, Singapore 179094',
        //   style: 'CompanyAddress',
        // },
        {
          text: reportType,
          fontSize: 18,
          alignment: 'center',
          color: '#000',
          margin: 20,
        },
        {
          text: [
            { text: 'Name: ', style: 'HeaderNameA' },
            { text: customer.name, style: 'HeaderNameB' },
          ],
        },
        {
          text: [
            { text: 'Email: ', style: 'HeaderNameA' },
            { text: customer.email, style: 'HeaderNameB' },
          ],
        },
        {
          text: [
            { text: 'Address: ', style: 'HeaderNameA' },
            { text: fullAddress, style: 'HeaderNameB' },
          ],
        },
        {
          text: [
            { text: 'Order history as at: ', style: 'HeaderNameB' },
            { text: new Date().toDateString(), style: 'HeaderNameB' },
          ],
          margin: [0, 10, 0, 10],
        },
        // {text: 'Email: ' + , fontSize: 12, bold: false, alignment: 'left', color: '#000',margin: [0, 10, 10, 0]},
        // {text: 'Address: ' + fullAddress, fontSize: 12, bold: false, alignment: 'left', color: '#000', margin: [0, 10, 10, 10], style: 'UserAddress'},
        // {text: 'Order history as at ' + new Date().toDateString(), color: '#000', margin: [0, 10, 60, 20]},
        // {text: 'but you can provide a custom styler as well', margin: [0, 20, 0, 8]},
        // {
        // 	style: 'tableExample',
        // 	table: {
        // 		headerRows: 1,
        // 		body: [
        // 			[{text: 'Header 1', style: 'tableHeader'}, {text: 'Header 2', style: 'tableHeader'}, {text: 'Header 3', style: 'tableHeader'}],
        // 			['Sample value 1', 'Sample value 2', 'Sample value 3'],
        // 			['Sample value 1', 'Sample value 2', 'Sample value 3'],
        // 			['Sample value 1', 'Sample value 2', 'Sample value 3'],
        // 			['Sample value 1', 'Sample value 2', 'Sample value 3'],
        // 			['Sample value 1', 'Sample value 2', 'Sample value 3'],
        // 		]
        // 	},
        // 	layout: {
        // 		hLineWidth: function (i, node) {
        // 			return (i === 0 || i === node.table.body.length) ? 2 : 1;
        // 		},
        // 		vLineWidth: function (i, node) {
        // 			return (i === 0 || i === node.table.widths.length) ? 2 : 1;
        // 		},
        // 		hLineColor: function (i, node) {
        // 			return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
        // 		},
        // 		vLineColor: function (i, node) {
        // 			return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
        // 		},
        // 	}
        // },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            body: [...exs],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 2 : 1;
            },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length ? 'black' : 'gray';
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? 'black'
                : 'gray';
            },
            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // paddingLeft: function(i, node) { return 4; },
            // paddingRight: function(i, node) { return 4; },
            // paddingTop: function(i, node) { return 2; },
            // paddingBottom: function(i, node) { return 2; },
            // fillColor: function (rowIndex, node, columnIndex) { return null; }
          },
        },
        {
          columns: [
            [
              {
                text: '*Order Value in USD',
                style: 'BottomPrice',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Thank you for your patronage.',
                style: 'thanksMsg',
              },
            ],
          ],
        },
      ],
      styles: {
        logostyle: {
          alignment: 'center',
          // margin: [0, 0, 0, 0]
        },
        CompanyAddress: {
          fontSize: 10,
          bold: false,
          alignment: 'center',
          color: '#000',
          // margin: [0, 10, 0, 20]
        },
        OrderRight: {
          fontSize: 12,
          bold: false,
          alignment: 'right',
          color: '#000',
          margin: [0, 50, 0, 0],
        },
        CustomerLeft: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 50, 0, 0],
        },
        OrderRight1: {
          fontSize: 12,
          bold: false,
          alignment: 'right',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        CustomerLeft1: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        Notes: {
          fontSize: 18,
          bold: true,
          alignment: 'left',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        BottomPrice: {
          fontSize: 10,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [2, 5, 0, 0],
        },
        thanksMsg: {
          fontSize: 12,
          margin: [10, 20, 0, 0],
        },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
        HeaderNameA: {
          fontSize: 12,
          bold: true,
          alignment: 'left',
          color: '#000',
          margin: [0, 10, 10, 0],
        },
        HeaderNameB: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 10, 10, 0],
        },
      },
    };
    pdfmake.createPdf(docDefinition).open();
  }

  async genPdf(order: any) {
    let reportType = 'Receipt',
      logo;

    if (order.paystatus != true) reportType = 'Invoice';
    const exs = [];
    const receiptNo = order.id;
    const paidamout = 0;
    const orderamount = order.orderamount;
    const grandTotal = orderamount + (orderamount * environment.gst) / 100;
    const balance = grandTotal - paidamout;
    const logoimge = '../assets/images/logo.png';

    let address, fullAddress;
    if (this.currentUser.address) {
      address = JSON.parse(this.currentUser.address);
      fullAddress =
        address.housedetail + ' - ' + address.pincode + ', ' + address.country;
    } else {
      fullAddress = 'Not available';
    }
    await this.toDataURL(logoimge).then((dataUrl) => {
      logo = dataUrl;
    });
    exs.push([
      {
        columns: [
          {
            width: 50,
            alignment: 'center',
            fontSize: 10,
            text: 'Item No.',
            style: 'tableHeader',
          },
          {
            width: 120,
            alignment: 'center',
            fontSize: 10,
            text: 'Sensor Name',
            style: 'tableHeader',
          },
          {
            width: 100,
            alignment: 'center',
            fontSize: 10,
            text: 'Resolution',
            style: 'tableHeader',
          },
          {
            width: 100,
            alignment: 'center',
            fontSize: 10,
            text: 'Unit Price',
            style: 'tableHeader',
          },
          {
            width: 50,
            alignment: 'center',
            fontSize: 10,
            text: 'Quantity',
            style: 'tableHeader',
          },
          {
            width: 100,
            alignment: 'center',
            fontSize: 10,
            text: 'Subtotal',
            style: 'tableHeader',
          },
        ],
      },
    ]);
    order.orderdetail.forEach((sensor, index) => {
      // let sensor = JSON.parse(currentValue);
      const name = sensor.operator.concat(' ', sensor.sensor);
      const description = sensor.resolution + 'm';
      const cost = this.dollarUS.format(sensor.cost);
      const qnty = 1;
      const subcost = this.dollarUS.format(sensor.cost);
      exs.push([
        {
          columns: [
            [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: index + 1,
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                width: 120,
                alignment: 'left',
                fontSize: 10,
                text: name,
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: description,
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: cost,
                margin: [35, 5, 0, 5],
              },
            ],
            [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: qnty,
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: subcost,
                margin: [0, 5, 0, 5],
              },
            ],
          ],
        },
      ]);
    });

    let docDefinition = {
      header: {
        margin: 10,
        columns: [
          {
            image: logo,
            width: 120,
          },
        ],
      },
      content: [
        // {
        //   text: 'logo',
        //   link: '../assets/images/logo.png',
        //   alignment: 'left',
        //   color: 'blue',
        // },
        {
          text: reportType,
          fontSize: 20,
          alignment: 'right',
          color: '#000',
        },
        {
          margin: [0, 25, 0, 0],
          text: 'Eartheye Space Technologies Pte Ltd',
          fontSize: 12,
          bold: true,
          alignment: 'left',
          color: '#000',
        },
        {
          text: '1 North Bridge Road',
          style: 'CompanyAddress',
        },
        {
          text: '#18-03, High Street Centre',
          style: 'CompanyAddress',
        },
        {
          text: 'Singapore 179094',
          style: 'CompanyAddress',
        },
        {
          columns: [
            [{ text: 'Bill to: ', style: 'CustomerLeft' }],
            [
              {
                text: ' Order No: ' + order.id,
                style: 'OrderRight',
                // text: [
                //   {text: 'Order No: ', bold:true, style:'OrderRight' },
                //  {text: order.id, style:'OrderRight' }
                // ],
              },
            ],
          ],
        },
        {
          columns: [
            [{ text: this.currentUser.name, style: 'CustomerLeft1' }],
            [
              {
                // text: [{text: reportType+' Date: ', bold:true, style:'OrderRight1' }, {text: new Date(order.createdAt).toDateString(), style:'OrderRight1' }],
                text:
                  reportType +
                  ' Date: ' +
                  new Date(order.createdAt).toDateString(),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Address: ' + fullAddress,
                style: 'CustomerLeft1',
                margin: [0, 0, 0, 10],
              },
            ],
            [
              {
                // text: [{text: reportType+' No: ', bold:true, style:'OrderRight1' }, {text: receiptNo, style:'OrderRight1' }],
                text: reportType + ' No: ' + receiptNo,
                style: 'OrderRight1',
              },
            ],
          ],
        },
        // {
        //   columns: [
        //     [{
        //       text: 'Address: ' + fullAddress,
        //       style: 'CustomerLeft1',
        //       margin: [0, 0, 0, 10]
        //     }
        //     ]]
        // },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*'],
            body: [
              ...exs,
              // ['Item No.', 'Item Name', 'Description', 'Unit Price','Quantity','Subtotal'],
              ///  orderdetail.map(o=>([o.name,o.name,o.name,o.name,o.name,o.name]))
            ],
          },
        },
        // {
        //   style: 'tableExample',
        //   table: {
        //     headerRows: 1,
        //     body: [
        //       ['Subtotal: ', this.dollarUS.format(orderamount)],
        //       ['GST (%):',  gstpercent.toFixed(2)],
        //       ['Total: ',this.dollarUS.format(grandTotal)],
        //       ['Balance Due: ',this.dollarUS.format(balance)],
        //     ],
        //     alignment: "right"
        //   },
        //   layout: 'noBorders'
        // },
        {
          columns: [
            [
              {
                text: 'Subtotal: ' + this.dollarUS.format(orderamount),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'GST (%): ' + environment.gst.toFixed(2),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Total: ' + this.dollarUS.format(grandTotal),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Balance Due: ' + this.dollarUS.format(balance),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Notes',
                style: 'Notes',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Price in USD',
                style: 'BottomPrice',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: '<Any other Notes>',
                style: 'BottomPrice',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: '<Eartheye Director Signature> 	',
                style: 'BottomPrice',
              },
            ],
          ],
        },
      ],
      styles: {
        CompanyAddress: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 10, 0, 0],
        },
        OrderRight: {
          fontSize: 12,
          bold: false,
          alignment: 'right',
          color: '#000',
          margin: [0, 50, 0, 0],
        },
        CustomerLeft: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 50, 0, 0],
        },
        OrderRight1: {
          fontSize: 12,
          bold: false,
          alignment: 'right',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        CustomerLeft1: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        Notes: {
          fontSize: 18,
          bold: true,
          alignment: 'left',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        BottomPrice: {
          fontSize: 13,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          margin: [5, 5, 0, 5],
          color: 'black',
        },
      },
    };
    pdfmake.createPdf(docDefinition).open();
    //pdfmake.createPdf(docDefinition).download();
  }

  genPdf_bak(status: string, order: any) {
    console.log(status, order);
    var dd = {
      content: [
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', 'auto', 100, '*'],

            body: [
              [
                { text: 'First', bold: true },
                { text: 'Second', bold: true },
                { text: 'Third', bold: true },
                { text: 'The last one', bold: true },
              ],
              ['Value 1', 'Value 2', 'Value 3', 'Value 4'],
              ['Val 2', 'Val 2', 'Val 3', 'Val 4'],
            ],
          },
        },
      ],
    };

    const documentDefinition = {
      content: 'This is an sample PDF printed with pdfMake',
    };
    pdfmake.createPdf(dd).open();
    // pdfmake.createPdf(dd).download();
  }

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
}
