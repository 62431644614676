<div class="row g-0 login-background">
  <div class="col-md-7 col-xs-7">
    <app-leftpan></app-leftpan>
  </div>
  <div class="col-md-5 col-xs-5">
    <div class="row g-0">
      <div class="col-md-3 col-xs-3"></div>
      <div class="col-md-7 col-xs-7 mt-100">
        <div class="flex-container">
          <mat-card class="example-card">
            <p class="title" style="text-align: center; margin: 8px">Login</p>
            <div [formGroup]="formGroup" (keydown)="submitForm($event)">
              <div>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label class="mat-label">Email</mat-label>
                  <input
                    matInput
                    placeholder="Your registered email"
                    [formControl]="emailField"
                    required
                  />
                  <mat-error *ngIf="emailField.invalid">{{
                    getErrorMessage(emailField)
                  }}</mat-error>
                  <mat-icon matSuffix>email</mat-icon>
                  <!-- <mat-hint>User Email</mat-hint> -->
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="outline" class="w-100 mt-30">
                  <mat-label class="mat-label">Password</mat-label>
                  <input
                    matInput
                    placeholder="Password"
                    [type]="hide ? 'password' : 'text'"
                    [formControl]="passwordField"
                    required
                  />
                  <mat-icon matSuffix (click)="hide = !hide">{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                  <!-- <mat-error *ngIf="passwordField.invalid">{{ getErrorMessage(passwordField) }}</mat-error> -->
                  <!-- <mat-hint>Enter password</mat-hint> -->
                </mat-form-field>
              </div>
              <div *ngIf="warnMsg" class="warn">{{ warnMsg }}</div>
              <div *ngIf="warnMsg">
                <div
                  *ngIf="warnMsg.includes('Wrong')"
                  style="text-align: right; padding: 8px 0"
                >
                  <span
                    (click)="forgotPswrd()"
                    style="cursor: pointer; color: royalblue"
                    >Forgot password?</span
                  >
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <span
                  class="w-100"
                  *ngIf="!warnMsg || !warnMsg.includes('sent')"
                  ><button
                    mat-button
                    class="btn"
                    type="submit"
                    (click)="onCloseConfirm()"
                    [disabled]="formGroup.invalid"
                  >
                    Login
                  </button></span
                >
              </div>
            </div>
          </mat-card>
          <!-- <label class="link-primary link-primary-font mt-10 txt-algin-left">Register as new user, <a href="/register" class="sign-up">Sign up</a></label> -->
          <label class="link-primary link-primary-font mt-10 txt-algin-left"
            >Register as new user,
            <span (click)="openDialogRegister()" class="sign-up"
              >Sign up</span
            ></label
          >
        </div>
      </div>
      <div class="col-md-2 col-xs-2"></div>
    </div>
  </div>
</div>
